import React from "react";
import { createGlobalStyle } from "styled-components";
import tw, { theme, GlobalStyles as BaseStyles } from "twin.macro";

const CustomStyles = createGlobalStyle`
  body {
    overflow-x: hidden !important;
    font-family: "Montserrat", sans-serif;

    &.hide-vertical-scroll::-webkit-scrollbar {
      display: none;
    }

    &.hide-vertical-scroll::-webkit-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
  }

  .strikethrough {
    ${tw`
      line-through
    `}
  }

  h1 {
    ${tw`
      text-7xl sm:text-8xl
    `}
  }
  
  h2 {
    ${tw`
      text-5xl sm:text-6xl
    `}
  }
  
  h3 {
    ${tw`
      text-4xl sm:text-5xl
    `}
  }
  
  h4 {
    ${tw`
      text-3xl sm:text-4xl
    `}
  }
  
  h5 {
    ${tw`
      text-2xl sm:text-3xl
    `}
  }
  
  h6 {
    ${tw`
      text-xl sm:text-2xl
    `}
  }

  h1, h2, h3, h4, h5, h6 {
    ${tw`
      leading-tight
    `}
  }
`;

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
);

export default GlobalStyles;
